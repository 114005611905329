import type { ComputedRef, MaybeRef } from 'vue'
import type {
  OtherRecurringChargesForm,
  RecurringChargesReviewTable,
} from '../types'
import { convertRecurringChargesToReviewTable } from '../utils/convertRecurringChargesToReviewTable'
import { joinPath, resolveRealPath, YearType } from '@manager'

export const useTable = (initialValue: MaybeRef<any[]>, objectPath: string) => {
  const dayjs = useDayjs()

  const { getProperty } = useManagerFormValue<OtherRecurringChargesForm>()
  const recurringChargePath = resolveRealPath(
    'recurringCharge.data[]',
    objectPath,
  )
  const recurringCharge = computed(
    () =>
      getProperty(recurringChargePath) as
        | OtherRecurringChargesForm['recurringCharge']['data'][number]
        | undefined,
  )
  const leaseStartDate = computed(() => recurringCharge.value?.commencementDate)
  const leaseEndDate = computed(() => recurringCharge.value?.expiryDate)

  // TABLE CONFIG
  const joinDataPath = (index: number) => joinPath(objectPath, `data[${index}]`)
  const increaseAsBaseRent = computed(
    () => recurringCharge.value?.recurringChargesIncreaseAsBaseRent ?? true,
  )
  const method = computed(() => recurringCharge.value?.method)
  const increase = computed(() => {
    switch (method.value) {
      case 'FixedAmount':
        return joinPath(recurringChargePath, 'increaseAmount')
      case 'FixedPercentage':
        return joinPath(recurringChargePath, 'increasePercentage')
      case 'Cpi':
        return undefined
      default:
        return undefined
    }
  })

  const { data, filteredData, addOrUpdate, batchUpdate, remove } =
    useTableData<RecurringChargesReviewTable>(initialValue, {
      path: objectPath,
      initialItem: computed(() => {
        return {
          method: recurringCharge.value?.method,
          fixedAmount: recurringCharge.value?.increaseAmount,
          fixedPercentage: recurringCharge.value?.increasePercentage,
          cpiPercent: recurringCharge.value?.cpiPercent,
          cpiCollar: recurringCharge.value?.cpiCollar,
          cpiCap: recurringCharge.value?.cpiCap,
          greaterOf: recurringCharge.value?.greaterOf,
          lesserOf: recurringCharge.value?.lesserOf,
          marketReviewCollar: null,
          marketReviewCap: null,
          marketReviewDate: null,
          amount: null,
          value: null,
        } satisfies Partial<RecurringChargesReviewTable>
      }),
      watchInitialItem: true,
      initialItemCondition: () => !increaseAsBaseRent.value,
      evaluate: (_, index) => {
        return {
          amount: computed(() => {
            // If `asBaseRent` is true, the `amount` will be evaluated in the BE.
            if (increaseAsBaseRent.value) return undefined

            // For now, only support FixedAmount and FixedPercentage
            if (
              !method.value ||
              !increase.value ||
              !['FixedAmount', 'FixedPercentage'].includes(method.value)
            )
              return undefined

            const previousAmountPath =
              index === 0
                ? joinPath(recurringChargePath, 'amount')
                : joinPath(joinDataPath(index - 1), 'amount')

            switch (method.value) {
              case 'FixedAmount':
                return `${previousAmountPath} + ${increase.value}`
              case 'FixedPercentage':
                return `${previousAmountPath} + (${previousAmountPath} * ${increase.value})`
            }
          }),
        }
      },
    })

  // Reset the `amount` and `value` when `asBaseRent` changes
  // When `asBaseRent` is true, the evaluation will be done in the BE.
  watch(increaseAsBaseRent, () => {
    batchUpdate({ amount: null, value: null, method: null })
  })

  const { generate } = useDatesGenerator(
    {
      startDate: computed(() =>
        leaseStartDate.value
          ? dayjs(leaseStartDate.value).add(1, 'year').toISOString()
          : null,
      ),
      endDate: leaseEndDate,
    },
    {
      onAdd: (index, { startDate }) => {
        addOrUpdate(index, {
          reviewDate: startDate.toISOString(),
          __status: REPEATER_ITEM_STATUS.GENERATED,
        })
      },
      onRemove: (index) => {
        const item = data.value[index]
        if (item) remove(item, index)
      },
      maxIterations: 200,
    },
  )

  watch(
    [leaseStartDate, leaseEndDate],
    () => {
      generate(YearType.LEASE_YEAR)
    },
    { immediate: true },
  )

  return {
    leaseStartDate,
    leaseEndDate,
    filteredData: computed(() =>
      filteredData.value.map(convertRecurringChargesToReviewTable),
    ),
  }
}
